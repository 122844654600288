import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { Gesture, GestureDetector, GestureHandlerRootView, TouchableWithoutFeedback, } from "react-native-gesture-handler";
import Animated, { useSharedValue, withSpring, useAnimatedStyle, runOnJS, } from "react-native-reanimated";
import { style } from "./Carousel.style";
const SPRING_CONFIG = {
    damping: 25,
    mass: 1,
    stiffness: 300,
};
const clamp = (min, max, value) => {
    "worklet";
    return Math.min(max, Math.max(min, value));
};
const Carousel = ({ activeIndex = 0, data, enabled = true, children, bullets, onActiveIndexChanged, onPress, style: customStyle, layoutAnimations, }) => {
    const [carouselDimensions, setCarouselDimensions] = useState();
    const handleOnLayout = useCallback(({ nativeEvent: { layout } }) => setCarouselDimensions({ width: layout.width, height: layout.height }), []);
    const carouselWidth = carouselDimensions === null || carouselDimensions === void 0 ? void 0 : carouselDimensions.width;
    const panTranslationX = useSharedValue(0);
    const panInitialTranslationX = useSharedValue(undefined);
    const panActiveRef = useRef(false);
    const activeIndexRef = useRef(activeIndex);
    activeIndexRef.current = activeIndex;
    const updatePanTranslationX = useCallback(({ translationX, enabled }) => {
        panTranslationX.value = enabled ? withSpring(translationX) : translationX;
    }, [panTranslationX]);
    useEffect(() => {
        if (panActiveRef.current || !carouselWidth) {
            return;
        }
        const translationX = -activeIndex * carouselWidth;
        runOnJS(updatePanTranslationX)({ translationX, enabled });
    }, [activeIndex, carouselWidth, enabled, panTranslationX, updatePanTranslationX]);
    const updateActiveIndex = useCallback((index) => {
        if (activeIndexRef.current === index) {
            return;
        }
        onActiveIndexChanged(index);
    }, [onActiveIndexChanged]);
    const handleOnActiveIndexChanged = useCallback((index) => {
        if (!carouselWidth) {
            return;
        }
        updateActiveIndex(index);
        panTranslationX.value = withSpring(-index * carouselWidth, SPRING_CONFIG);
    }, [carouselWidth, panTranslationX, updateActiveIndex]);
    const handleOnPress = useCallback(() => {
        if (panActiveRef.current) {
            return;
        }
        onPress === null || onPress === void 0 ? void 0 : onPress();
    }, [onPress]);
    const panGesture = useMemo(() => Gesture.Pan()
        .withTestId("carousel-pan-gesture")
        .enabled(enabled)
        .maxPointers(1)
        .activeOffsetX([-20, 20])
        .onBegin(() => {
        if (Platform.OS !== "web") {
            return;
        }
        panActiveRef.current = true;
        panInitialTranslationX.value = panTranslationX.value;
    })
        .onStart(() => {
        if (Platform.OS === "web") {
            return;
        }
        panActiveRef.current = true;
        panInitialTranslationX.value = panTranslationX.value;
    })
        .onUpdate(({ translationX }) => {
        panActiveRef.current = true;
        if (panInitialTranslationX.value === undefined || !carouselWidth) {
            return;
        }
        const tX = panInitialTranslationX.value + translationX;
        const index = clamp(0, data.length - 1, Math.round(-tX / carouselWidth));
        panTranslationX.value = tX;
        runOnJS(updateActiveIndex)(index);
    })
        .onEnd(({ translationX, velocityX }) => {
        if (panInitialTranslationX.value === undefined || !carouselWidth) {
            return;
        }
        const tX = panInitialTranslationX.value + translationX;
        const indexDelta = Math.round(translationX / carouselWidth);
        const velocityIndex = Math.abs(indexDelta) > 0 ? 0 : clamp(-1, 1, Math.abs(velocityX) > 100 ? -velocityX : 0);
        const index = clamp(0, data.length - 1, Math.round(-tX / carouselWidth) + velocityIndex);
        panTranslationX.value = withSpring(-index * carouselWidth, SPRING_CONFIG, (finishedWithoutCancellation) => {
            if (!finishedWithoutCancellation) {
                return;
            }
            panActiveRef.current = false;
        });
        panInitialTranslationX.value = undefined;
        runOnJS(updateActiveIndex)(index);
    }), [carouselWidth, data.length, enabled, panInitialTranslationX, panTranslationX, updateActiveIndex]);
    const animatedStyle = useAnimatedStyle(() => ({ transform: [{ translateX: panTranslationX.value }] }), [panTranslationX]);
    const ChildrenWrapper = useMemo(() => (onPress ? TouchableWithoutFeedback : Fragment), [onPress]);
    const { entering, exiting, layout } = layoutAnimations || {};
    return (React.createElement(View, { style: [style.container, customStyle === null || customStyle === void 0 ? void 0 : customStyle.container], testID: "carousel" },
        React.createElement(View, { style: style.carousel, testID: "carousel-layout", onLayout: handleOnLayout }, carouselWidth && (React.createElement(GestureHandlerRootView, { style: { flex: 1 } },
            React.createElement(GestureDetector, { gesture: panGesture },
                React.createElement(Animated.View, { style: [style.carouselTrack, { width: carouselWidth * data.length }, animatedStyle], testID: "carousel-track" }, data.map((item, index) => (React.createElement(Animated.View, { key: item.id || index, entering: entering, exiting: exiting, layout: layout, testID: "carousel-item", style: [
                        style.carouselItem,
                        ...(enabled && onPress ? [style.pointer] : []),
                        { width: carouselWidth },
                    ] },
                    React.createElement(ChildrenWrapper, { ...(onPress ? { onPress: handleOnPress } : {}) }, children({ index, item })))))))))),
        bullets && (React.createElement(View, { style: style.bullets, testID: "carousel-bullets" }, bullets({ activeIndex, count: data.length, onChange: handleOnActiveIndexChanged })))));
};
export { Carousel };
